// src/Views/HomePage.js
import React, { useEffect, useRef, useState } from 'react';
import './HomePage.css';
import Header from './Header.js';
import Footer from './Footer.js';

function HomePage() {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const carouselRef = useRef(null);
  const boxesRef = useRef(null);
  const [displayText, setDisplayText] = useState('student');
  const words = ['student', 'innovator', 'researcher', 'learner', 'builder'];
  const [wordIndex, setWordIndex] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const carousel = carouselRef.current;
    const boxes = boxesRef.current;
    const dotContainer = carousel.querySelector('.carousel-dots');

    dotContainer.innerHTML = '';

    const projectCount = boxes.children.length;
    for (let i = 0; i < projectCount; i++) {
      const dot = document.createElement('div');
      dot.classList.add('dot');
      if (i === 0) dot.classList.add('active');
      dotContainer.appendChild(dot);
    }

    const dots = dotContainer.querySelectorAll('.dot');

    const handleScroll = () => {
      const scrollPosition = boxes.scrollLeft;
      const boxWidth = boxes.clientWidth;
      
      let newIndex = Math.round(scrollPosition / boxWidth);
      newIndex = Math.max(0, Math.min(newIndex, projectCount - 1));

      dots.forEach((dot, index) => {
        dot.classList.toggle('active', index === newIndex);
      });
    };

    boxes.addEventListener('scroll', handleScroll);

    dots.forEach((dot, index) => {
      dot.addEventListener('click', () => {
        boxes.scrollTo({ left: index * boxes.clientWidth, behavior: 'smooth' });
      });
    });

    // Cleanup function
    return () => {
      boxes.removeEventListener('scroll', handleScroll);
      dots.forEach((dot, index) => {
        dot.removeEventListener('click', () => {});
      });
    };
  }, []);

  useEffect(() => {
    const typingSpeed = 150; // Speed for typing
    const deletingSpeed = 100; // Speed for deleting
    const pauseTime = 2000; // Time to pause at complete word

    const typeWriter = () => {
      const currentWord = words[wordIndex];
      
      if (isDeleting) {
        setDisplayText(currentWord.substring(0, displayText.length - 1));
        
        if (displayText.length === 1) {
          setIsDeleting(false);
          setWordIndex((prev) => (prev + 1) % words.length);
        }
      } else {
        setDisplayText(currentWord.substring(0, displayText.length + 1));
        
        if (displayText.length === currentWord.length) {
          setTimeout(() => setIsDeleting(true), pauseTime);
          return;
        }
      }
    };

    const timer = setTimeout(
      typeWriter,
      isDeleting ? deletingSpeed : typingSpeed
    );

    return () => clearTimeout(timer);
  }, [displayText, isDeleting, wordIndex]);

  return (
    <div className="homepage">
      <div className="homepage-content">
        <Header isMobile={isMobile} />
        <main className="content">
          <div className="intro">
            <p style={{ textAlign: 'left', lineHeight: '1.25' }}>
              Hi <span role="img" aria-label="Waving hand">👋</span> 
              <br /><br />
              My name is Ojas Nimase (OH-juhs nim-SAY) and I'm a{' '}
              <span style={{ color: '#4a90e2' }}>{displayText}</span> at the{' '}
              <span style={{ color: '#ff6b6b' }}>University of Southern California</span>, studying Physics + CS.
              Some of my interests are mathematics, computer science, philosophy, and physics.
              In my free time, I enjoy reading, listening to music, and playing card games.
              <br /><br />
              I would love to hear from you. You can contact me <a href="https://ojasnimase.com/contact" target="_blank" rel="noopener noreferrer" className="contact-link" style={{ color: 'inherit', textDecoration: 'none' }}><em>here</em></a>.
            </p>
          </div>

          <div className="research-section">
            <h1>research</h1>
            <div className="research-intro">
              <p style={{ textAlign: 'left', lineHeight: '1.25' }}>
                {/* I'm extremely grateful to have been advised by the following people:
                <br />
                - Professor <a href="https://dornsife.usc.edu/profile/james-alcala/" target="_blank" rel="noopener noreferrer" style={{ color: 'inherit', textDecoration: 'none' }}><u>James Alcala</u></a> ()
                <br />
                - Professor <a href="https://dornsife.usc.edu/profile/krishna-narayanamurti/" target="_blank" rel="noopener noreferrer" style={{ color: 'inherit', textDecoration: 'none' }}><u>Krishna Narayanamurti</u></a> ()
                <br />
                - Professor <a href="https://sanghyun-hong.com/" target="_blank" rel="noopener noreferrer" style={{ color: 'inherit', textDecoration: 'none' }}><u>Sanghyun Hong</u></a> ()
                <br /><br /> */}
                My main research interests are{' '}
                <span style={{ color: '#2ecc71' }}>computer vision</span> and{' '}
                <span style={{ color: '#f1c40f' }}>natural language processing</span>, but I value{' '}
                <span style={{ color: '#e67e22' }}>interdisciplinary research</span>. You can find a full list 
                of my publications on my <a href="https://scholar.google.com/citations?user=bY5j5mEAAAAJ&hl=en" target="_blank" rel="noopener noreferrer" style={{ color: 'inherit', textDecoration: 'none' }}><em>Google Scholar profile</em></a>.
                Here are some of my select publications:
              </p>
            </div>
            <div className="research-pubs">
              <p style={{ textAlign: 'left', lineHeight: '1.5' }}>
                <b>When Do "More Contexts" Help with Sarcasm Recognition?</b>
                <br></br>
                <i>Ojas Nimase</i> and <a href="https://sanghyun-hong.com/" target="_blank" rel="noopener noreferrer" style={{ color: 'inherit', textDecoration: 'none' }}><u>Sanghyun Hong</u></a>.
                <br></br>
                The 2024 Joint International Conference on Computational Linguistics, 
                    Language Resources and Evaluation (<b>LREC-COLING</b>). 2024
                <br></br>
                <a href="https://aclanthology.org/2024.lrec-main.1525/" target="_blank" rel="noopener noreferrer" style={{ color: 'inherit', textDecoration: 'none' }}><u>Paper</u></a>.
              </p>
            </div>
          </div>
          
          <div className="projects">
            <h1>projects</h1>
            <div className="project-carousel" ref={carouselRef}>
              <div className="project-boxes" ref={boxesRef}>
              <a href="https://github.com/USCRPL" className="project-box">
                  <span className="icon" role="img" aria-label="Rocket">🚀</span>
                  <div className="content">
                    <span className="title">USC Rocket Propulsion Lab</span>
                    <span className="text">Launching rockets</span>
                  </div>
                </a>
                <a href=" " className="project-box">
                  <span className="icon" role="img" aria-label="Playing cards">🃏</span>
                  <div className="content">
                    <span className="title">Poker Trojans</span>
                    <span className="text">USC's poker leaderboard</span>
                  </div>
                </a>
                <a href="https://github.com/ojuicen/Kaggle-Competitions/tree/main" className="project-box">
                  <span className="icon" role="img" aria-label="Man technologist">👨‍💻</span>
                  <div className="content">
                    <span className="title">Kaggle</span>
                    <span className="text">Some of my work on Kaggle</span>
                  </div>
                </a>
                <a href=" " className="project-box">
                  <span className="icon" role="img" aria-label="School building">🏫</span>
                  <div className="content">
                    <span className="title">Northwest Data Science Collective</span>
                    <span className="text">AI literacy initiative with career resources, hackathons, etc for younger students</span>
                  </div>
                </a>
              </div>
              <div className="carousel-dots"></div>
            </div>
          </div>
        </main>
        <Footer />
      </div>
    </div>
  );
}

export default HomePage;
